// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-tsx": () => import("./../../../src/pages/blog/index.tsx" /* webpackChunkName: "component---src-pages-blog-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-demo-zabka-index-tsx": () => import("./../../../src/pages/demo_zabka/index.tsx" /* webpackChunkName: "component---src-pages-demo-zabka-index-tsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-privacy-policy-tales-ds-index-tsx": () => import("./../../../src/pages/privacy-policy-tales-ds/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tales-ds-index-tsx" */),
  "component---src-pages-product-communication-tsx": () => import("./../../../src/pages/product/communication.tsx" /* webpackChunkName: "component---src-pages-product-communication-tsx" */),
  "component---src-pages-product-employer-branding-tsx": () => import("./../../../src/pages/product/employer-branding.tsx" /* webpackChunkName: "component---src-pages-product-employer-branding-tsx" */),
  "component---src-pages-product-onboarding-offboarding-tsx": () => import("./../../../src/pages/product/onboarding-offboarding.tsx" /* webpackChunkName: "component---src-pages-product-onboarding-offboarding-tsx" */),
  "component---src-pages-product-organizer-tsx": () => import("./../../../src/pages/product/organizer.tsx" /* webpackChunkName: "component---src-pages-product-organizer-tsx" */),
  "component---src-pages-use-cases-index-tsx": () => import("./../../../src/pages/use-cases/index.tsx" /* webpackChunkName: "component---src-pages-use-cases-index-tsx" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */)
}

