import { wrapRootElement as rootWrapper } from "src/components/wrapRootElement";


export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    if (typeof window !== undefined) {
        window.scrollTo(0, 0)
    }
    return false
}

export const wrapRootElement = rootWrapper;
